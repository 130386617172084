import React, { Component } from "react";
import { navigate } from "gatsby";
import Layout from "../layouts/ja";
import SEO from "../components/seo";
import langData from "../data/static-pages/pages/verify-account/ja";
import { guestInfo } from "../services/Guest";
import { getUserToken, checkVerificationEmail } from "../services/Auth";
import { saveState } from "state/utils/localStorage";
import VerifyAccount from "../components/TWComponents/VerifyAccount";
export default class VerifyAccountPage extends Component {
  intervalID = 0;
  dateNow = new Date().getTime();

  constructor(props, context) {
    super(props, context);
    this.params = props.location.search.split("?email=");
    this.email = this.params[1] !== undefined ? this.params[1] : "";
  }

  componentDidMount() {
    this.intervalID = setInterval(() => this.checkUser(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  checkUser() {
    if (process.env.GATSBY_ENABLE_AUTH0 === "true") return;
    if (this.email === "" && !Boolean(getUserToken)) navigate("/ja/signup");

    (async () => {
      const { data, status_code } = await checkVerificationEmail();

      if (status_code === 200108) {
        if (data.status === 1) {
          if (await guestInfo()) {
            saveState("setupTime", this.dateNow);
            navigate("/ja/member");
          }
        }
      } else {
        navigate("/ja/signup");
      }
    })();
  }

  render() {
    return (
      <Layout location={this.props.location} footer_margin={true}>
        <SEO
          title="Verify Your Account "
          keyword="Verify Your Account"
          decription="Verify Your Account"
        />

        <VerifyAccount
          title={langData.title}
          description={`${langData.description_1} ${this.email} ${langData.description_2}`}
        />
      </Layout>
    );
  }
}
